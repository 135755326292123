const cspHeader = `default-src 'self';
  script-src 'self' 'unsafe-inline' 'unsafe-eval' https://apis.google.com;
  style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
  img-src 'self' data: https://havassmeapp.com;
  connect-src 'self' https://api.havassmeapp.com;
  font-src 'self' https://fonts.gstatic.com;
  frame-src 'self';
`;

const cspHeaderProduction = `
  default-src 'self';
  script-src 'self' 'unsafe-inline' 'unsafe-eval' https://apis.google.com;
  style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
  img-src 'self' data: https://havassmeapp.com;
  connect-src 'self' https://api.havassmeapp.com;
  font-src 'self' https://fonts.gstatic.com;
  frame-src 'self';
`;

const cspHeaderDevelopment = `
  default-src 'self';
  script-src 'self' 'unsafe-inline' 'unsafe-eval';
  style-src 'self' 'unsafe-inline';
  img-src 'self' data:;
  connect-src 'self';
  font-src 'self';
  frame-src 'self';
`;

module.exports = process.env.NODE_ENV === 'production' ? cspHeaderProduction : cspHeaderDevelopment;


module.exports =  cspHeader

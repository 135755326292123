import React from "react";
import {
    FaCss3Alt,
    FaDocker,
    FaHtml5,
    FaNodeJs,
    FaReact,
} from "react-icons/fa";
import {
    SiJavascript,
    SiKubernetes,
    SiNginx,
    SiTailwindcss,
    SiTypescript,
} from "react-icons/si";
import { DiJenkins, DiMongodb, DiPostgresql } from "react-icons/di";
import Dotnet7Icon from "../icons/technologies/Dotnet7Icon";
import RedisIcon from "../icons/technologies/RedisIcon";
import NextjsIcon from "../icons/technologies/NextjsIcon";
import StyleXIcon from "../icons/technologies/StyleXIcon";
import RabbitMQIcon from "../icons/technologies/RabbitMQIcon";
import { Flex, Icon, Text, useColorMode } from "@chakra-ui/react";

export const techLogos = [
    {
        src: FaHtml5,
        title: "Html 5",
        lightColor: "#e34c26",
        darkColor: "#d94a20",
        isReactIcon: true,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: FaCss3Alt,
        title: "Css 3",
        lightColor: "#264de4",
        darkColor: "#1e40af",
        isReactIcon: true,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: SiJavascript,
        title: "Javascript",
        lightColor: "#F0DB4F",
        darkColor: "#e5cd34",
        isReactIcon: true,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: SiTypescript,
        title: "Typescript",
        lightColor: "#007acc",
        darkColor: "#005c99",
        isReactIcon: true,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: FaReact,
        title: "React.js",
        lightColor: "#61dbfb",
        darkColor: "#58c9e6",
        isReactIcon: true,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: NextjsIcon,
        title: "Nextjs",
        lightColor: "#000",
        darkColor: "#fff",
        isReactIcon: false,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: FaNodeJs,
        title: "Nodejs",
        lightColor: "#8cc84b",
        darkColor: "#7aa93f",
        isReactIcon: true,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: Dotnet7Icon,
        title: ".Net Core 8.0",
        lightColor: "#6D429C",
        darkColor: "#583478",
        isReactIcon: false,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: RedisIcon,
        title: "Redis",
        lightColor: "#AD2115",
        darkColor: "#941c12",
        isReactIcon: false,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: DiMongodb,
        title: "Mongodb",
        lightColor: "#4DB33D",
        darkColor: "#3f9a31",
        isReactIcon: true,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: StyleXIcon,
        title: "StyleXjs",
        lightColor: "#000",
        darkColor: "#fff",
        isReactIcon: false,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: SiTailwindcss,
        title: "Tailwindcss",
        lightColor: "#38bdf8",
        darkColor: "#2d9fc7",
        isReactIcon: true,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: DiPostgresql,
        title: "Postgresql",
        lightColor: "#336791",
        darkColor: "#2a5372",
        isReactIcon: true,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: SiNginx,
        title: "Nginx",
        lightColor: "#009900",
        darkColor: "#007a00",
        isReactIcon: true,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: FaDocker,
        title: "Docker",
        lightColor: "#0db7ed",
        darkColor: "#0aa6cc",
        isReactIcon: true,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: SiKubernetes,
        title: "Kubernetes",
        lightColor: "#3970e4",
        darkColor: "#305ab3",
        isReactIcon: true,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: DiJenkins,
        title: "Jenkins",
        lightColor: "#676767",
        darkColor: "#fff",
        isReactIcon: true,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
    {
        src: RabbitMQIcon,
        title: "RabbitMQ",
        lightColor: "#FF6600",
        darkColor: "#d95000",
        isReactIcon: false,
        boxSize: {
            base: "40px",
            md: "45px",
            lg: "50px",
        },
        fontSize: {
            base: "12px",
            md: "14px",
            lg: "15px",
        },
    },
];

const TechLogos = () => {
    const { colorMode } = useColorMode();

    return (
        <Flex wrap="wrap" justify="center" align="center" gap={5}>
            {techLogos.map((logo, index) => {
                const color = colorMode === "light" ? logo.lightColor : logo.darkColor;

                return (
                    <Flex
                        key={`${logo.title}-${index}`}
                        direction="column"
                        justify="center"
                        align="center"
                        gap={2.5}
                    >
                        {logo.isReactIcon ? (
                            <Icon as={logo.src} boxSize={logo.boxSize} color={color} />
                        ) : (
                            <logo.src boxSize={logo.boxSize} fill={color} />
                        )}
                        <Text
                            textAlign="center"
                            fontSize={logo.fontSize}
                            fontWeight="semibold"
                            userSelect="none"
                        >
                            {logo.title}
                        </Text>
                    </Flex>
                );
            })}
        </Flex>
    );
};

export default TechLogos;

import { ttCode } from "../lib/ttCode";
import { gtmCode } from "../lib/gtmCode";
import { pinterestAnalyticsCode } from "../lib/pinterestAnalyticsCode";
import { googleAnalyticsCode } from "../lib/googleAnalyticsCode";
import { facebookPixelCode } from "../lib/facebookPixelCode";
import { i18n } from "../next-i18next.config";
import cspHeader from "../cspHeader";
import { API_BASE_URL, BASE_URL } from "../siteConfig";
import theme from "../src/theme";
import {
  FaInstagram,
  FaYoutube,
  FaLinkedin,
  FaFacebook,
  FaPinterest,
  FaDribbble,
  FaBehance,
  FaVimeoV,
  FaTiktok,
} from "react-icons/fa";

import { FaThreads, FaXTwitter } from "react-icons/fa6";

const alternateLinks = i18n.locales.map((locale) => ({
  hreflang: i18n.defaultLocale === locale ? "x-default" : locale,
  href: `${BASE_URL}${locale === i18n.defaultLocale ? "" : `/${locale}`}`,
}));

const displayTypes = {
  FULLSCREEN: "fullscreen", //Uygulama tam ekran modunda açılır.
  STANDALONE: "standalone", //Uygulama, kendi penceresinde bir tarayıcı kontrolü olmadan açılır.
  MINIMAL_UI: "minimal-ui", //Uygulama, tarayıcı kontrollerinin minimum haliyle açılır.
  BROWSER: "browser", //Uygulama, normal bir tarayıcı sekmesi olarak açılır.
};

const platforms = [
  { label: "Google Play", value: "play" },
  { label: "Apple App Store", value: "itunes" },
  { label: "Huawei AppGallery", value: "huawei" },
  { label: "Samsung Galaxy Store", value: "samsung" },
];

export const site = {
  title: `Hüseyin Fehimli • Özel Yazılım Geliştirme & Dijital Pazarlama & E-ticaret Çözümleri & Prodüksiyon & Tasarım`,
  API_BASE_URL: API_BASE_URL,
  baseUrl: BASE_URL,
  publisher: `Hüseyin Fehimli`,
  ogImage: BASE_URL + "/images/og-image.jpg",
  twImage: BASE_URL + "/images/tw-image.png",
  logoUrl: BASE_URL + "/logo.png",
  imageAlt: "site-logo",
  description: `layouts`,
  name: ``,
  keywords: "",
  isRobotIndex: true,
  themeColor: theme.colors.primary,
  alternateLinks: alternateLinks,
  lang: i18n.defaultLocale,
  mailAddress: "info@huseyinfehimli.com",
  address: {
    streetAddress: ``,
    addressLocality: ``,
    addressRegion: ``,
    postalCode: ``,
    addressCountry: ``,
  },
  telephone: "",
  sosyalMediaLinks: [
    {
      id: 1,
      label: "instagram",
      link: "https://www.instagram.com/",
      isActive: true,
      icon: FaInstagram,
    },
    {
      id: 2,
      label: "facebook",
      link: "https://www.facebook.com/",
      isActive: true,
      icon: FaFacebook,
    },
    {
      id: 3,
      label: "threads",
      link: "https://www.threads.net/",
      isActive: false,
      icon: FaThreads,
    },
    {
      id: 4,
      label: "tiktok",
      link: "https://www.tiktok.com/",
      isActive: false,
      icon: FaTiktok,
    },
    {
      id: 5,
      label: "youtube",
      link: "https://www.youtube.com/",
      icon: FaYoutube,
      isActive: true,
    },
    {
      id: 6,
      label: "twitter",
      link: "https://twitter.com/",
      icon: FaXTwitter,
      isActive: true,
    },
    {
      id: 7,
      label: "pinterest",
      link: "https://www.pinterest.com/",
      isActive: true,
      icon: FaPinterest,
    },
    {
      id: 8,
      label: "linkedin",
      link: "https://tr.linkedin.com/",
      isActive: false,
      icon: FaLinkedin,
    },
    {
      id: 9,
      label: "dribbble",
      link: "https://dribbble.com/",
      isActive: false,
      icon: FaDribbble,
    },
    {
      id: 10,
      label: "behance",
      link: "https://www.behance.net/",
      isActive: false,
      icon: FaBehance,
    },
    {
      id: 11,
      label: "vimeo",
      link: "https://vimeo.com/",
      isActive: false,
      icon: FaVimeoV,
    },
  ],
  analyticsCodes: {
    pinterestAnalyticsCode,
    googleAnalyticsCode,
    facebookPixelCode,
    gtmCode,
    ttCode,
  },
  /*
  relatedApplications: [
    {
      platform: "play",
      url: "",
    },
    {
      platform: "itunes",
      url: "",
    },
  ],*/
  platforms: platforms,
  icons: [
    {
      rel: "icon",
      src: "/icon.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "152x152",
      src: "/icon.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "120x120",
      src: "/icon.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "76x76",
      src: "/icon.png",
    },
    {
      rel: "apple-touch-icon",
      src: "/icon.png",
    },
  ],
  contentSecurityPolicy: cspHeader.replace(/\n/g, ""),
  prefer_related_applications: true,
  displayTypes,
};

import * as React from "react";
import {
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  Button,
  Box,
  Flex,
  Heading,
  keyframes,
} from "@chakra-ui/react";

import Link from "next/link";
import TechLogos from "./TechLogos";

const rainbowAnimation = keyframes`
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 50% 0;
  }
  100% {
    background-position: 100% 0;
  }
`;

const RainbowText = ({ text }) => (
  <Text
    as="span"
    bgGradient="linear(to left, #6666ff, #0099ff, #00ff00, #ff3399, #6666ff, #0099ff)"
    bgClip="text"
    color="transparent"
    bgSize="300% 100%"
    w="fit-content"
    sx={{
      animation: `${rainbowAnimation} 2s linear infinite`,
      backgroundSize: "300% 100%",
    }}
  >
    {text}
  </Text>
);

const HeroSection = () => {
  const [projectType, setProjectType] = React.useState("Web Sitesi");

  const projectTypes = [
    "Web Sitesi",
    "E-ticaret",
    "Haber Platformu",
    "Eğitim Platformu",
    "Portföy",
    "Blog",
    "Kurumsal Sitesi",
  ];

  React.useEffect(() => {
    const interval = setInterval(() => {
      setProjectType((prevType) => {
        const currentIndex = projectTypes.indexOf(prevType);
        const nextIndex = (currentIndex + 1) % projectTypes.length;
        return projectTypes[nextIndex];
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container maxW="5xl" px={{ base: 6, md: 3 }} py={15}>
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent="center"
        align="center"
      >
        <Stack direction="column" spacing={6} justifyContent="center" p={2}>
          <Box
            display="flex"
            justifyContent="center"
            as="article"
            fontFamily={"Montserrat"}
          >
            <Heading
              fontSize={{ base: "4xl", md: "5xl" }}
              lineHeight={1.2}
              fontWeight="bold"
              textAlign="center"
              fontFamily={"Montserrat"}
            >
              <chakra.span
                color="teal"
                bg="linear-gradient(transparent 55%, #83e9e7 50%)"
              />
              Dijital dünyada fark yaratın ve
              <RainbowText text={" başarıya "} />
              ulaşmak için bize katılın.
            </Heading>
          </Box>
          <Text
            lineHeight="1.375"
            fontWeight="400"
            fontSize="xl"
            textAlign="center"
            color="gray.500"
          >
            Yenilikçi ve özgün stratejilerle işinizi dijital dünyada öne
            çıkarıyoruz. İşinizi rakiplerinizden farklı ve benzersiz kılan
            yaklaşımlarımızla dijital alanda güçlenmenize yardımcı oluyoruz!
          </Text>

          <TechLogos />

          <Flex justifyContent={"center"}>
            <Stack
              direction={{ base: "row", sm: "row", md: "row" }}
              spacing={{ base: 3, sm: 5 }}
              mb={{ base: "3rem !important", sm: 0 }}
              flexWrap="wrap"
            >
              <Link href="/start-project" passHref>
                <Button
                  minW={{ base: "100%", sm: "auto" }}
                  h={12}
                  px={6}
                  color="white"
                  size="lg"
                  rounded="md"
                  mb={{ base: 2, sm: 0 }}
                  zIndex={5}
                  lineHeight={1}
                  transition="all 0.3s ease"
                  colorScheme="purple"
                >
                  <chakra.span>{projectType} Projesi Başlat</chakra.span>
                </Button>
              </Link>

              <Link href="/demo" passHref>
                <Box
                  d="flex"
                  justifyContent="center"
                  bg={useColorModeValue("white", "gray.800")}
                  w={{ base: "100%", sm: "auto" }}
                  border="1px solid"
                  borderColor="gray.300"
                  p={3}
                  lineHeight={1.18}
                  rounded="md"
                  boxShadow="md"
                >
                  Demo İste
                </Box>
              </Link>
            </Stack>
          </Flex>
        </Stack>
      </Flex>
    </Container>
  );
};



export default HeroSection;

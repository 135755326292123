import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { DefaultSeo } from "next-seo";
import routes from "../../routes";
import { useRouter } from "next/router";
import { locales } from "../../locales";

function PageWrapper({
  children,
  currentPage,
  isDynamicPage = false,
  isCtaShow = true,
}) {
  const { locale, asPath, query } = useRouter();

  const currentRouteKey = isDynamicPage
    ? `/${currentPage}/:id`
    : `/${currentPage}`;
  const currentRoute = routes[locale]?.[currentRouteKey];

  if (!currentRoute) {
    return <>{children}</>;
  }

  const pageTitle = currentRoute.meta.title.replace("%s", query.id || "");
  const { description, canonical } = currentRoute.meta;

  const _SEO = {
    title: pageTitle,
    description,
    canonical,
    images: {
      url: "",
      alt: description,
    },
  };

  // Meta tags for alternate languages
  const alternateLinks = Object.values(locales)
    .filter((locale) => locale.active)
    .map((locale) => ({
      rel: "alternate",
      hrefLang: locale.hl,
      href: `${canonical}${canonical.endsWith("/") ? "" : "/"}${
        locale.hl
      }${asPath}`,
    }));

  return (
    <Box maxW={"full"} my={3}>
      <DefaultSeo
        title={_SEO.title}
        description={_SEO.description}
        canonical={_SEO.canonical}
        openGraph={{
          type: "website",
          locale: locale,
          siteName: "",
          title: _SEO.title,
          description: _SEO.description,
          images: [
            {
              url: _SEO.images.url,
              width: 1200,
              height: 630,
              alt: "Og Image Alt",
            },
          ],
        }}
        additionalMetaTags={[
          { name: "language", content: locale },
          { name: "robots", content: "index, follow" },
          ...alternateLinks.map((link) => ({
            key: link.hrefLang,
            ...link,
          })),
          {
            rel: "alternate",
            hrefLang: "x-default",
            href: `${canonical}`,
          },
        ]}
      />

      <Flex direction={"column"} gap={2}>
        {children}
      </Flex>
    </Box>
  );
}

export default PageWrapper;
